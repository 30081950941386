import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Breadcrumb, Modal, Card } from "react-bootstrap";
import "./TeamBio.scss"
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import HTMLReactParser from "html-react-parser";
import ContactFormPage from "../forms/contact";
import { isMobile, isDesktop } from "react-device-detect";
import PlayVideo from "../PlayVideo/PlayVideo";
 
// Header component

const TeamBio = (props) => {
    const [isPlaying, setPlaying] = useState(false);
    const [show, setShow] = useState(false);

    const ImageRenderList = ({ item, imagename, renderer }) => {
        let processedImages = JSON.stringify({});
        if (props?.data?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = props?.data?.imagetransforms?.Tile_Image_Transforms;
        }
        return (
            <ImageTransform
                imagesources={item?.Tile_Image?.url}
                renderer="pic-src"
                imagename={imagename}
                attr={{ alt: item?.Tile_Image?.alternativeText ? item?.Tile_Image?.alternativeText : item?.Name, className: 'm-0' }}
                imagetransformresult={processedImages}
                lazyLoading={false}
                id={item?._id}
            />
        )
    }

    return (
        <React.Fragment>
            <section className="team-bio">
                <Container>
                    <Row>
                        <Col md={12} className="bio-wrapper">
                            <div className="bio-img">
                                <ImageRenderList item={props?.data} imagename={"teams.Tile_Image.banner"} renderer="pic-src" />
                                {/* {props?.data?.Video_Url && <i className="video-icon" onClick={() => { setPlay(true) }}></i>} */}
                                {(props?.data?.Video_Url && !isPlaying) &&
                                    <React.Fragment>
                                        <i
                                            className="video-icon"
                                            onClick={async () => {
                                                setPlaying(true);
                                            }}
                                        />
                                    </React.Fragment>
                                }

                                {(props?.data?.Video_Url && isPlaying) && <div className="inline-video inline-video-container">
                                    {isPlaying &&
                                        <React.Fragment>
                                            <div className="d-block">
                                                <PlayVideo
                                                    isOpen={isPlaying}
                                                    videoId={props?.data?.Video_Url}
                                                    stopPlay={setPlaying}
                                                    width={'100%'} height={isDesktop ? '600px' : '100%'}
                                                    fullscreen={isDesktop ? false : true}
                                                />
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>}
                            </div>

                            <div className="bio-details-wrapper"> 
                                <div className="bio-details team-bio-contact-info">
                                    <h1>{props?.data?.Name}</h1>
                                    <span className="sm-info">{props?.data?.Designation}</span>
                                    <div className="contact-info">
                                        <a href="javascript:;" onClick={() => setShow(true)} className="btn d-none d-md-block">
                                            get in touch
                                        </a>
                                        <div className="info">
                                            <a href={`tel:${props?.data?.Mobile}`} className="mail phone d-none d-md-flex">
                                                <i className="icon-phone-grey"></i>
                                                {props?.data?.Mobile}
                                            </a>
                                            <a href="javascript:;" onClick={() => setShow(true)} className="mail">
                                                <i className="icon-mail"></i>
                                                <span>{props?.data?.Email}</span>
                                            </a>
                                            <a href={`https://wa.me/${props?.data?.Mobile}`} className="whatsapp" target="_blank">
                                                <i className="icon-whatsapp"></i>
                                                {props?.data?.Mobile}
                                            </a>
                                        </div>
                                        <div className="info">
                                            {/* <a href={`https://wa.me/${props?.data?.Mobile}`} className="whatsapp" target="_blank">
                                                <i className="icon-whatsapp"></i>
                                                {props?.data?.Mobile}
                                            </a> */}
                                            {props?.data?.Whatsapp_OR_code &&
                                                <a href={`https://wa.me/${props?.data?.Mobile}`} className="" target="_blank">
                                                    <img src={props?.data?.Whatsapp_OR_code?.url} className="no-link" alt={props?.data?.Whatsapp_OR_code?.alternativeText} width={100} height={100} />

                                                </a>
                                            } 
                                        </div>

                                        {props?.data?.Select_Languages?.length > 0 && <div className="contact-us">
                                            <span className="talk-us">
                                                Talk to me in:
                                            </span>
                                            {props?.data?.Select_Languages?.map((item, index) =>
                                                <div className="country" key={index}>
                                                    <img src={item.Icon.url} className="no-link" title={item.Name} alt={item.Name} width={20} />
                                                </div>
                                            )}
                                        </div>}
                                    </div>

                                </div>
                                <div className="top-content team-content">
                                    {props?.data?.Bio !== null && <div className="content-wrapper">
                                        {HTMLReactParser(props?.data?.Bio)}
                                    </div>}
                                </div>
                            </div>
                        </Col>
                        {/* <Col md={12} lg={6} className="top-content">
                            {props?.data?.Bio !== null && <div className="content-wrapper">
                                {HTMLReactParser(props?.data?.Bio)}
                            </div>}
                        </Col> */}
                    </Row>
                </Container>
            </section>
            <Modal className="form-popup" onHide={() => setShow(false)} show={show} contentClassName="p-0" size="sm">

                <Modal.Body className="p-5">
                    <div className="card-title h5 text-center mb-3">Get in Touch - {props?.data?.Name}</div>
                    <button onClick={() => setShow(false)} type="button" className="close-modal">
                        <i className="icon-close-modal"></i>
                    </button>
                    <div className="form-block career-form">
                        <ContactFormPage email={props?.data?.Email} formname={`Person Contact`} />
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}
export default TeamBio
