import { graphql } from "gatsby";
import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Seo from "../components/seo";
import TeamBio from "../components/TeamBio/TeamBio";

const TeamTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.team;
    return (
        <React.Fragment>
            <Seo
                title={GQLPage.Name + " - " + GQLPage.Designation} 
                description={`Get to know about ${GQLPage.Name} here. Contact one of our estate agents for assistance in finding the right property for you.`}
                location={props.location}
            />
            <Header menuLinks={[]}/>
            <TeamBio
                data={GQLPage}
            />
            <Footer
                popularSearch="Popular_About" showBreadcrumb = {true}
            />
        </React.Fragment>
    )
}

export default TeamTemplate;


export const pageQuery = graphql`
    query teamDetail($id: ID!) {
        glstrapi {
            team(id: $id, publicationState: LIVE) {
                _id
                Url
                Name
                Email
                Designation
                Category {
                    Title
                    _id
                    Sort
                }
                Bio
                Mobile
                Sort
                Tile_Image {
                    url
                    alternativeText
                }
                Select_Languages {
                    Name
                    Code
                    Icon {
                        url
                        alternativeText
                    }
                }
                Publish
                Signature {
                    url
                    alternativeText
                }
                Whatsapp_OR_code {
                    url
                    alternativeText
                }
                imagetransforms
                Video_Url
            }
            globalModule {
                Book_A_Appointment {
                    _id
                    Book_Title
                    Description
                    Cta_Label
                    Cta_Link {
                        _id
                        URL
                    }
                }
                Lets_Work_Together {
                    _id
                    Title
                    Sm_Title
                    Cta_1_Label
                    Cta_2_Label
                    Cta_1_Link {
                        URL
                        _id
                    }
                    Cta_2_Link {
                        URL
                        _id
                    }
                }
            }
        }
    }
`

